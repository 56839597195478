<template>
	<div class="d-flex justify-content-center py-5">
		<div class="spinner-border" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		layout: 'empty',
		async mounted() {
			await api.Auth.logout()
			this.$router.push({name: 'login'})
		}
	}
</script>
